import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { appConfig } from '../../../configs/appConfig';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f8f8f8',
        padding: '20px 0',
        marginTop: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Our Office
            </Typography>
            <Typography>
              { appConfig.address }
            </Typography>
            <Typography>{ appConfig.phoneNumber }</Typography>
            <Typography>{ appConfig.email }</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom>
              Links
            </Typography>
            <Link href="#" color="inherit" underline="none">
              About Us
            </Link>
            <br />
            <Link href="#" color="inherit" underline="none">
              Our Products
            </Link>
            <br />
            <Link href="#" color="inherit" underline="none">
              News
            </Link>
            <br />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom>
              Social
            </Typography>
            <Link href="https://www.facebook.com" color="inherit" underline="none">
              Facebook
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
