import { useMemo } from 'react';
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import DefaultLayout from './layouts/DefaultLayout';
import routeConfig from './configs/routeConfig';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import { ContactPage } from '@mui/icons-material';


function App() {
  const theme = useMemo(() => createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#4A3AFF"
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFFFFF",
            borderBottom: "2px solid rgb(244, 160, 47, 0.8)",
            boxShadow: "none",
          },
          colorPrimary: {
            color: "#000000"
          }
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#FFFFFF",
            color: "#000000"
          }
        }
      },
    }
  }), []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>

          <Route
            path={routeConfig.home}
            element={
              <DefaultLayout>
                <HomePage />
              </DefaultLayout>
            }
          />

          <Route
            path={routeConfig.about}
            element={
              <DefaultLayout>
                <AboutPage />
              </DefaultLayout>
            }
          />


          <Route
            path={routeConfig.contact}
            element={
              <DefaultLayout>
                <ContactPage />
              </DefaultLayout>
            }
          />

          <Route
            path={routeConfig.default}
            element={
              <Navigate to={routeConfig.home} replace />
            }
          />


        </Routes>
      </Router>

    </ThemeProvider>
  );
}

export default App;
