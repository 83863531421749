import { Container, Typography } from "@mui/material";

const AboutPage = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Soychi Corp: Your Trusted Partner
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'left', mb: 4 }}>
                Soychi Corp operates in the field of agricultural exports. Our mission is to bring high-quality Vietnamese agricultural products such as fruits, dried fruits, and nuts to the world.
                Soychi Corp is always proud to be the bridge between our hard-working farmers and our overseas partners. We are trusted by small trading companies as well as larger partners overseas.

            </Typography>
        </Container>
    );
};
export default AboutPage;