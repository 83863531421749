import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { Product } from "../../data/interfaces";

interface ProductItemProps {
    children?: ReactNode;
    product: Product;
}

const ProductItem: FC<ProductItemProps> = ({ product, children }) => {
    return (
        <Card sx={{ maxWidth: 345, height: 300, display: 'flex', flexDirection: 'column' }}>
            <CardMedia
                component="img"
                sx={{
                    width: "100%",
                    height: 200,
                    objectFit: "cover"
                }}
                image={product.img}
                title={product.title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {product.description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProductItem;