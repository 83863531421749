
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';

const items = [
  {
    name: "Image 1",
    // url: "/assets/images/sliders/slider1.png",
    url: "https://i.ibb.co/bbspDtm/slider1.png"
    

  },
  {
    name: "Image 2",
    // url: "/assets/images/sliders/slider2.png",
    url: "https://i.ibb.co/3s7q5jW/slider2.png"
    

  },
  {
    name: "Image 3",
    // url: "/assets/images/sliders/slider3.png",
    url: "https://i.ibb.co/ZGbTBkz/slider3.png"
    

  },
];


const Slider = () => {
  return (
    <Carousel
    sx={{
      border: "none"
    }}>
      {items.map((item, index) => (
        <Paper key={index} sx={{
          border: "none",
          width: "100%",
          maxHeight: 400
        }}>
          <img src={item.url} alt={item.name} style={{ width: '100%', height: "80%" }} />
        </Paper>
      ))}
    </Carousel>
  );
}

export default Slider;
