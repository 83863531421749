const routeConfig = {
  home: "/",
  product: "/",
  news: "/",
  about: "/about",
  contact: "/contact",
  default: "/*"
};

export default routeConfig;
