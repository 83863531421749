import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FC, ReactNode } from "react";

interface DefaultLayoutProps {
  children: ReactNode;
}
 

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        overflow: "hidden"
      }}
    >
      <Header />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          px: {
            md: 12,
            sm: 8,
            xs: 4
          },
          overflow: "hidden"
        }}
        py={4}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}


export default DefaultLayout;
