import { Box, Typography } from "@mui/material";
import ProductList from "../../components/Product/ProductList";
import { freshFruitGroup } from "../../data/productGroups";
import Slider from "../../components/Slider";

const HomePage = () => {
    return <Box
        sx={{
            px: {
                md: 16,
                xs: 4
            }
        }}>

        <Slider/>

        <Box
            sx={{
                textDecoration: "none",
                borderRadius: 2,
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: 25,
                color: "rgba(30, 38, 78, 1)",
                p: 2,
                textAlign: 'center',
                backgroundColor: 'rgba(245, 245, 245, 1)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >

        <Typography
        id="product"
        sx={{
            textDecoration: "none",
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: 25,
            color: "rgba(30, 38, 78, 1)",
            p: 2,
            textAlign: 'center',
        }}
    >
        Our Products
    </Typography>
        <ProductList products={freshFruitGroup.products} />
    </Box>

    </Box>
}

export default HomePage;