import { Box, Grid, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import ProductItem from "./ProductItem";
import { Product } from "../../data/interfaces";

interface ProductListProps {
    title?: string;
    children?: ReactNode;
    products: Product[];
}


const ProductList: FC<ProductListProps> = ({ title, products, children }) => {
    return <Box sx={{
        mb: 10
    }}>
        {/* <Typography
            sx={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                fontSize: 26,
                mb: 4
            }}
        >{title}</Typography> */}

        <Grid container spacing={4} justifyContent={"flex-start"}>
            {
                products?.map((product) => {
                    return <Grid item xs={12} md={3}>
                        <ProductItem product={product} key={product.id} />
                    </Grid>
                })
            }

        </Grid>

    </Box>
}


export default ProductList;